import Swiper from "swiper";
import { Navigation, EffectFade, EffectCreative, Controller, Thumbs, Pagination, Autoplay } from "swiper/modules";

/**
 * @param swiperInit
 */
export default function swiperInit() {
	new Swiper(`.section-home-1 .swiper`, {
		slidesPerView: 1,
		spaceBetween: 0,
		modules: [EffectFade, Autoplay],
		autoplay: {
			delay: 3000,
		},
		effect: "fade",
		speed: 1000,
	});
	new Swiper(`.section-home-prize .swiper`, {
		slidesPerView: 1,
		spaceBetween: 32,
		speed: 1000,
		modules: [Navigation, Pagination],
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
		},
		pagination: {
			el: ".section-home-prize .swiper-pagination",
			clickable: true,
		},
		navigation: {
			prevEl: ".section-home-prize .btn-prev",
			nextEl: ".section-home-prize .btn-next",
		},
	});

	var slideThumbJudges = new Swiper(`.wrap-judge-avatar .swiper`, {
		slidesPerView: 3,
		spaceBetween: 16,
		speed: 1000,
		freeMode: true,
		watchSlidesProgress: true,
	});

	const [judgesImage, judgesImageBig] = [".wrap-judge-info", ".box-image"].map(
		(selector) =>
			new Swiper(`.section-home-judges ${selector} .swiper`, {
				slidesPerView: 1,
				spaceBetween: 32,
				effect: "creative",
				modules: [EffectCreative, Controller, Thumbs, Autoplay],
				autoplay: {
					delay: 4000,
				},
				thumbs: {
					swiper: slideThumbJudges,
				},
				creativeEffect: {
					prev: {
						translate: [0, 0, -400],
						opacity: 0,
					},
					next: {
						translate: ["100%", 0, 0],
						opacity: 1,
					},
				},
				observeParents: true,
				observer: true,
				speed: 1000,
			})
	);
	judgesImageBig.controller.control = judgesImage;
	judgesImage.controller.control = judgesImageBig;

	/*====================  ====================*/
	const [candidateOne, candidateTwo] = ["#col-num-0", "#col-num-1"].map(
		(item, index) =>
			new Swiper(`.section-home-candidate .wrap-slide ${item} .swiper`, {
				spaceBetween: 15,
				slidesPerView: 1.5,
				loop: true,
				init: false,
				direction: "horizontal",
				speed: 10000 + 1000 * index,
				modules: [Autoplay],
				autoplay: {
					delay: 0,
					reverseDirection: index == 1 ? true : false,
				},
				breakpoints: {
					768: {
						spaceBetween: 32,
						slidesPerView: 3,
					},
					1024: {
						spaceBetween: 32,
						slidesPerView: "auto",
						direction: "vertical",
					},
				},
			})
	);
	window[`candidateOne`] = candidateOne;
	window[`candidateTwo`] = candidateTwo;
	new Swiper(`.candinate-detail .swiper`, {
		slidesPerView: 1,
		spaceBetween: 30,
		speed: 1000,
		modules: [Navigation, Pagination],
		pagination: {
			el: ".candinate-detail .swiper-pagination",
			clickable: true,
		},
		navigation: {
			prevEl: ".candinate-detail .btn-prev",
			nextEl: ".candinate-detail .btn-next",
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
}
