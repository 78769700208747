import AOS from "aos";
import lozad from "lozad";
import { setBackgroundElement, detectCloseElement, buttonToTop, clickScrollToDiv, appendCaptchaASP } from "./helper";
import { header } from "./header";
import swiperInit from "./swiper";
import { inputUpload } from "./input-upload";
import { animation } from "./animation";
var inlineSVG = require("inline-svg");

$(document).ready(function () {
	handleInlineSvg();
	setBackgroundElement();
	header.init();
	swiperInit();
	handleTimelineHome();
	inputUpload();
	Fancybox.bind(".item-gallery-img [data-fancybox]", {
		Thumbs: {
			type: "modern",
		},
	});
	// setTimeout(() => {
	// 	if ($("#modal-popup-success").length) {
	// 		Fancybox.show([{ src: "#modal-popup-success", type: "inline" }]);
	// 	}
	// }, 1000);
	countUpNumber();
	switchTabForm();
	if (window.matchMedia("(min-width: 1024px)").matches && $(".homepage").length) {
		animation.splitText();
	} else {
		["candidateOne", "candidateTwo"].forEach((item) => {
			window[item].init();
		});
	}
	animation.init(observer);
	openTabNation();
	toolbar();
	observeInputFile();
	processAjaxTabLink();
});
/*==================== Tab Link Ajax ====================*/
function processAjaxTabLink() {
	if (!$(".tab-link-ajax").length) return;
	const $parent = $(".ajaxResponse");
	$(".tab-link-ajax .wrap-tab-primary li a").on("click", function (e) {
		e.preventDefault();
		const url = $(this).attr("href");
		$(this).closest("li").addClass("active").siblings().removeClass("active");
		$.ajax({
			url: url,
			contentType: "html",
			beforeSend: function (data) {
				$parent.addClass("notShow");
				$parent.append('<div class="loading"></div>');
			},
			success: async function (data) {
				await new Promise((resolve) => setTimeout(resolve, 300));
				$parent.html($(data).find(".ajaxResponse").html());
				observer.observe();
				handleInlineSvg();
				await new Promise((resolve) => setTimeout(resolve, 300));
				$parent.find(".loading").remove();
				$parent.removeClass("notShow");
			},
			completed: function (data) {},
		});
	});
}
/*==================== Custom input file ====================*/
export function observeInputFile() {
	if (!$("#ctl00_mainContent_ctl01_uplAttachFile_ClientState").length) return;
	// Select the div element you want to observe
	const myDiv = document.querySelector("#ctl00_mainContent_ctl01_uplAttachFile_ClientState");
	// Create a new MutationObserver object
	const observer = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
			appendItemFile();
		});
	});
	// Configure the observer to listen for changes to the "class" attribute
	const config = { attributes: true, characterData: true, childList: true };
	// Start observing the target div element
	function appendItemFile() {
		const files = [];
		$(".RadUpload ul li").each(function (index) {
			const fileName = $(this).find(".ruUploadSuccess").text();
			const htmlText = (fileName) => `<div class="item-inputted-file">${fileName} <div class="close" data-index="${index}"><img src="/Data/Sites/1/skins/default/img/icon-delete.svg"/></div></div>`;
			if (fileName) {
				files.push(htmlText(fileName));
			}
		});
		if (files.length) {
			$(".wrap-inputted-file").show();
		} else {
			$(".wrap-inputted-file").hide();
		}
		$(".wrap-inputted-file").html(files);
	}
	observer.observe(myDiv, config);
	function handleDeleteFile() {
		$(".wrap-inputted-file").on("click", ".close", function () {
			const index = $(this).data("index");
			$(".RadUpload ul li").eq(index).find(".ruRemove").click();
		});
	}
	handleDeleteFile();
}

/*==================== Toolbar ====================*/
function toolbar() {
	$("body").on("click", "#pullmenucms", function () {
		$(this).closest(".cmsadminpanel").toggleClass("isClose");
	});
}
/*==================== OpenPageFromSelectNation ====================*/
function openTabNation() {
	$(".select-country select").on("change", function () {
		const nation = $(this).val();
		console.log("🚀 ~ file: main.js:37 ~ nation:", nation);
		window.open(`${nation}`, "_bank");
	});
}
/*==================== Switch ====================*/
function switchTabForm() {
	$(".tab-options .item-option").on("click", function () {
		const idTab = $(this).data("tab");
		$(this).addClass("active").siblings().removeClass("active");
		$(`.tab-content#${idTab}`).show().siblings().hide();
	});
}
function countUpNumber() {
	var nodeCount = $(".countup");
	const options = {
		duration: 3,
		separator: ".",
		enableScrollSpy: true,
	};
	var nodeAnimation;
	nodeCount.each(function (index) {
		var value = $(nodeCount[index]).data("number");
		nodeAnimation = new countUp.CountUp(nodeCount[index], value, options);
		nodeAnimation.start();
	});
}
function handleInlineSvg() {
	inlineSVG.init(
		{
			svgSelector: "img.svg", // the class attached to all images that should be inlined
			initClass: "js-inlinesvg", // class added to <html>
		},
		function () {
			console.log("All SVGs inlined");
		}
	);
}
function handleTimelineHome() {
	if (!$(".section-home-timeline").length) return;
	$(".item-timeline").each(function () {
		const startDate = new Date($(this).data("start-date"));
		const endDate = new Date($(this).data("end-date"));
		// Current Date
		var currentDate = new Date();
		// Calculate the total duration in milliseconds
		var totalDuration = endDate - startDate;
		// Calculate the elapsed duration in milliseconds
		var elapsedDuration = currentDate - startDate;
		// Calculate the progress percentage
		var progressPercent = (elapsedDuration / totalDuration) * 100;
		progressPercent = progressPercent > 100 ? 100 : progressPercent;
		// Round the percentage to two decimal places
		progressPercent = Math.round(progressPercent * 100) / 100;
		progressPercent = progressPercent < 0 ? 0 : progressPercent;
		$(this).find(".line").css("--height", `${progressPercent}%`);
	});
}

/*==================== Aos Init ====================*/
AOS.init({
	offset: 100,
});
/*==================== Lazyload JS ====================*/
var observer = lozad(); // lazy loads elements with default selector as '.lozad'
window["observer"] = observer;
observer.observe();
