import LocomotiveScroll from "locomotive-scroll";
export const animation = {
	splitText: function () {
		const elements = document.querySelectorAll(".is-split-text");
		elements.forEach((element) => {
			const text = element.textContent;
			const characters = text.split("");
			const splitText = characters.map((char) => `<span class="split-char">${char}</span>`).join("");
			element.innerHTML = splitText;
		});
	},
	init: function () {
		if (!$(".homepage").length) return;
		const scroll = new LocomotiveScroll({
			el: document.querySelector(".homepage main"),
			smooth: true,
			multiplier: 0.8,
			lerp: 0.1,
			offset: [100,0]
		});
		window["scrollLocomotive"] = scroll;
		scroll.on("call", (func) => {
			$(document).trigger(func);
		});
	},
};
$(document).on("slideCandidate", function (el) {
	["candidateOne", "candidateTwo"].forEach((item) => {
		window[item].init();
	});
});
